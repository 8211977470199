import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaTwitter, FaYoutube, FaInstagram, FaTiktok } from 'react-icons/fa';

import SEO from '../components/SEO/SEO';

const Homepage = (props) => {
  const { pageContext } = props;

  const { videoPageTableData } = pageContext;

  useEffect(() => {
    const animatedElements = document.querySelectorAll('.animate');

    animatedElements.forEach((el) => el.classList.add('in'));
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{ class: 'landing-page--wrapper' }}
        bodyAttributes={{
          class: 'frame landing-page',
        }}
      />
      <SEO videoPageTableData={videoPageTableData} />
      <div id="outer-wrapper" className="animate translate-z-in">
        <div id="inner-wrapper">
          <div id="table-wrapper" className="center">
            <div className="container">
              <div id="row-header">
                <header>
                  <a
                    href="/"
                    id="brand"
                    className="animate animate fade-in animation-time-3s"
                  >
                    <h1>
                      <span className="pink-color">
                        {videoPageTableData.Header_Title_Part_One}
                      </span>
                      <span className="header-slim">
                        {videoPageTableData.Header_Title_Part_Two}
                      </span>
                    </h1>
                  </a>
                </header>
              </div>

              <div id="row-content">
                <div id="content-wrapper">
                  <div
                    id="content"
                    className="animate fade-in animation-time-05s"
                  >
                    <div className="center">
                      <a
                        href={videoPageTableData.Onlyfans}
                        className="btns btn-landing-pageCenter"
                      >
                        <span>
                          <strong>Exclusive Content</strong>
                        </span>
                      </a>
                      <a
                        href="https://www.fleshlight.ca/collections/fleshlight-girls/products/autumn-falls"
                        className="btns hidden"
                      >
                        Order My Fleshlight
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div id="row-footer">
                <footer>
                  <div className="social-icons pink-color">
                    <a
                      href={videoPageTableData.Twitter}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className="animate fade-in animation-time-1s delay-07s"
                    >
                      <FaTwitter
                        color="#ff69d5"
                        className="landing__page__footer-icon"
                      />
                      <i className="fab fa-twitter"></i>
                    </a>

                    <a
                      href={videoPageTableData.Youtube}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className="animate fade-in animation-time-1s delay-06s"
                    >
                      <FaYoutube
                        color="#ff69d5"
                        className="landing__page__footer-icon"
                      />
                      <i className="fab fa-youtube"></i>
                    </a>
                    {/* 
                    <a
                      href="https://www.twitch.tv/autumnfallsx3"
                      target="_blank"
                      className="animate fade-in animation-time-1s delay-05s"
                    >
                      <FaTwitch />
                      <i className="fab fa-twitch"></i>
                    </a>
                    */}
                    <a
                      href={videoPageTableData.Instagram}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className="animate fade-in animation-time-1s delay-06s"
                    >
                      <FaInstagram
                        color="#ff69d5"
                        className="landing__page__footer-icon"
                      />
                      <i className="fab fa-instagram"></i>
                    </a>

                    <a
                      href={videoPageTableData.Tiktok}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className="animate fade-in animation-time-1s delay-07s"
                    >
                      <FaTiktok
                        color="#ff69d5"
                        className="landing__page__footer-icon"
                      />
                      <i className="fab fa-tiktok"></i>
                    </a>
                  </div>
                </footer>
              </div>
            </div>
          </div>

          <div className="background-wrapper has-vignette">
            <div className="bg-transfer">
              <img src="/img/background.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="backdrop"></div>
    </>
  );
};

export default Homepage;
